.other {

  &__wrapper {
  }

  &__title {
  }

  &__table {
    overflow: auto;
    border: 0.1rem solid var(--clr-primary-400);
    padding-top: 6rem;
    padding-right: 4rem;
    padding-bottom: 6rem;
    padding-left: 4rem;

    table {
      border-collapse: collapse;

      thead {
        th {
          padding-top: 0.8rem;
          padding-right: 1.5rem;
          padding-bottom: 0.8rem;
          padding-left: 1.5rem;
          line-height: 150%;
          line-height: 150%;
          text-align: start;
          color: var(--clr-default-550);

          &:first-child {
            padding-left: initial;
            min-width: 22rem;

            @media (max-width: em(992)) {
              min-width: 15rem;
            }

            @media (max-width: em(575)) {
              min-width: 12rem;
            }
          }
        }
      }

      tbody {

        tr {
          border-bottom: 0.1rem solid var(--clr-default-600);
        }

        td {
          padding-top: 0.8rem;
          padding-right: 1.5rem;
          padding-bottom: 0.8rem;
          padding-left: 1.5rem;
          line-height: 150%;

          &:first-child {
            padding-left: initial;
          }
        }
      }

      @media (max-width: em(767)) {
        min-width: 74rem;
      }
    }

    @media (max-width: em(767)) {
      padding-top: 2rem;
      padding-right: 2rem;
      padding-bottom: 2rem;
      padding-left: 2rem;
    }
  }
}
