.betbonus {

  &__wrapper {
    margin-bottom: 10rem;
  }

  &__title {
  }

  &__text {
    margin-right: auto;
    margin-bottom: 5rem;
    margin-left: auto;
    max-width: 90rem;
    text-align: center;

    p:not(:last-child) {
      margin-bottom: 3rem;

      @media (max-width: em(767)) {
        margin-bottom: 1.5rem;
      }
    }

    @media (max-width: em(767)) {
      margin-bottom: 3rem;
    }
  }

  &__link {
    margin-right: auto;
    margin-left: auto;
  }

  &__banner {
    max-width: 129.5rem;
  }
}

