.register {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    right: -49rem;
    top: 33rem;
    z-index: -1;
    border-radius: 50%;
    width: 106rem;
    height: 106rem;
    background-color: var(--clr-primary-650);
    filter: blur(6rem);
  }

  &__wrapper {
  }

  &__title {
    margin-bottom: 4rem;

    @media (max-width: em(575)) {
      margin-bottom: 3rem;
    }
  }

  &__info {
    position: relative;

    @media (max-width: em(1600)) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    @media (max-width: em(992)) {
      flex-direction: column;
    }
  }

  &__content {
    max-width: 85.5rem;

    @media (max-width: em(1600)) {
      margin-right: 2rem;
      max-width: 55rem;
    }

    @media (max-width: em(992)) {
      margin-right: initial;
      margin-bottom: 3rem;
      max-width: initial;
    }
  }

  &__text {
    margin-bottom: 3rem;

    p:not(:last-child) {
      margin-bottom: 2rem;
    }
  }

  &__list {
    list-style: disc;
    padding-left: 2rem;

    &--first {
      margin-bottom: 3rem;
    }
  }

  &__item {
    font-size: fluid-font(2rem, 1.8rem);
    line-height: 140%;

    &:not(:last-child) {
      margin-bottom: 2rem;
    }
  }

  &__image {
    position: absolute;
    right: -14.5rem;
    top: 50%;
    width: 56.3rem;
    height: 44.6rem;
    transform: translateY(-50%);

    @media (max-width: em(1600)) {
      position: initial;
      right: initial;
      top: initial;
      width: initial;
      height: initial;
      max-width: 56.3rem;
      transform: initial;
    }
  }
}

