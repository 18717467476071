.license {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    left: -42rem;
    top: 17rem;
    z-index: -1;
    border-radius: 50%;
    width: 106rem;
    height: 106rem;
    background-color: var(--clr-primary-650);
    filter: blur(6rem);
  }

  &__wrapper {
    margin-bottom: 10rem;

    @media (max-width: em(1320)) {
      margin-bottom: 5rem;
    }
  }

  &__title {
  }

  &__text {
    text-align: center;

    p:not(:last-child) {
      margin-bottom: 3rem;
    }
  }

  &__banner {
    margin-right: auto;
    margin-left: auto;
  }
}

