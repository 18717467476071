.header {
  z-index: 1;

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 5rem;

    @media (max-width: em(992)) {
      padding-top: 3rem;
    }
  }

  &__link {
    @media (max-width: em(992)) {
      margin-right: 2.5rem;
    }

    @media (max-width: em(575)) {
      margin-right: initial;
    }
  }
}
