.live {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    left: -49rem;
    top: -7rem;
    z-index: -1;
    border-radius: 50%;
    width: 106rem;
    height: 106rem;
    background-color: var(--clr-primary-650);
    filter: blur(6rem);
  }

  &__wrapper {
  }

  &__title {
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: em(992)) {
      flex-direction: column-reverse;
    }
  }

  &__info {
    max-width: 64rem;

    @media (max-width: em(1380)) {
      max-width: 55rem;
    }

    @media (max-width: em(992)) {
      margin-bottom: 3rem;
      max-width: initial !important;
    }
  }

  &__text {
    margin-bottom: 5rem;

    p:not(:last-child) {
      margin-bottom: 3rem;
    }
  }

  &__link {
  }

  &__image {
    margin-right: 3rem;
    max-width: 65rem;

    @media (max-width: em(992)) {
      margin-right: initial;
    }
  }
}
