.footer {
  padding-top: 10rem;
  padding-bottom: 10rem;

  @media (max-width: em(1560)) {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  @media (max-width: em(767)) {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
