.hero {
  position: relative;
  margin-top: -11rem;
  padding-top: 15rem;
  padding-bottom: 40rem;
  background-image: -webkit-image-set(url(../img/hero/bg@2x.jpg) 2x, url(../img/hero/bg@1x.jpg) 1x);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  &::before {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 100%;
    height: 31rem;
    background-image: url(../img/hero/decor-border.svg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    transform: translateX(-50%);
  }

  @media (max-width: em(1680)) {
    padding-bottom: 25rem;
  }

  @media (max-width: em(992)) {
    padding-bottom: 17rem;
  }

  &__wrapper {
    position: relative;

    @media (max-width: em(1680)) {
      display: flex;
    }

    @media (max-width: em(992)) {
      flex-direction: column;
    }
  }

  &__content {
    max-width: 63rem;

    @media (max-width: em(1680)) {
      margin-right: 3rem;
      max-width: 50rem;
    }

    @media (max-width: em(992)) {
      margin-right: initial;
      margin-bottom: 3rem;
      max-width: initial;
    }
  }

  &__title {
    margin-bottom: 1.5rem;
    font-weight: 700;
    font-size: fluid-font(5.2rem, 2.4rem);
  }

  &__text {
    margin-bottom: 3rem;

    p {
      line-height: 188%;

      &:not(:last-child) {
        margin-bottom: 2rem;
      }
    }
  }

  &__image {
    position: absolute;
    right: -51.5rem;
    top: 0;
    width: 113.5rem;

    @media (max-width: em(1680)) {
      position: initial;
      right: initial;
      top: initial;
      width: initial;
      max-width: 113.5rem;
    }
  }

  &__link {
   
  }
}
