$burgerMQ: em(992);

.menu {
  &__list {
    display: flex;
    align-items: center;

    @media (max-width: $burgerMQ) {
      flex-direction: column;
      margin: auto;
      padding-block: 4rem;
    }
  }

  &__item {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      left: 50%;
      bottom: -4px;
      width: 0;
      height: 2px;
      background-color: var(--clr-default-500);
      transform: translate(-50%, 0);
      transition: width 0.3s;
    }

    @media (hover: hover) {
      &:hover::before {
        width: 100%;
        transition: width 0.3s;
      }
    }

    a {
      font-size: 1.7rem;
      color: var(--clr-default-100);

      @media (max-width: $burgerMQ) {
        font-weight: 600;
        font-size: 2rem;
      }
    }

    &:not(:last-child) {
      margin-right: 3rem;

      @media (max-width: $burgerMQ) {
        margin-right: 0;
        margin-bottom: 3rem;
      }
    }
  }

  @media (max-width: $burgerMQ) {
    position: fixed;
    right: -100%;
    top: 0;
    z-index: 10;
    display: flex;
    overflow: auto;
    width: 100%;
    height: 100%;
    max-width: 32rem;
    background-color: var(--clr-default-900);
    transition: right var(--transition);

    &--active {
      right: 0;
      transition: right var(--transition);
    }
  }
}
