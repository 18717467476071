.app {
  padding-bottom: 14rem;

  @media (max-width: em(1320)) {
    padding-bottom: 10rem;
  }

  @media (max-width: em(992)) {
    padding-bottom: 5rem !important;
  }

  &__wrapper {
  }

  &__title {
    margin-bottom: 4rem;

    @media (max-width: em(992)) {
      margin-bottom: 3rem;
    }
  }

  &__text {
    margin-bottom: 5rem;
    text-align: center;

    p:not(:last-child) {
      margin-bottom: 3rem;

      @media (max-width: em(992)) {
        margin-bottom: 1.5rem;
      }
    }

    @media (max-width: em(992)) {
      margin-bottom: 3rem;
    }
  }

  &__link {
    margin-right: auto;
    margin-left: auto;
  }
}

