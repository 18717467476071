.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.section {
  padding-top: 14rem;

  @media (max-width: em(1320)) {
    padding-top: 10rem;
  }

  @media (max-width: em(992)) {
    padding-top: 5rem !important;
  }
}

.title {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4rem;
  min-height: 10.7rem;
  font-size: fluid-font(3.6rem, 2.1rem);
  line-height: 133%;
  text-transform: uppercase;

  .span__wrapper {
    color: var(--clr-default-100);
  }

  span {
    color: var(--clr-default-500);
  }

  &:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    height: 10.7rem;
    background-image: url(../img/ui/decor-title.svg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    transform: translate(-50%, -50%);

    @media (max-width: em(1320)) {
      display: none;
    }
  }

  @media (max-width: em(1320)) {
    display: block;
    margin-right: auto;
    margin-left: auto;
    min-height: initial;
    text-align: center;
  }

  @media (max-width: em(767)) {
    margin-bottom: 2.5rem;
  }
}

.text {
  line-height: 188%;
}

.main__link {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 2.5rem;
  padding-left: 2.5rem;
  width: max-content;
  min-width: 22rem;
  min-height: 6rem;
  font-family: 'Strong';
  font-weight: 400;
  font-size: 2rem;
  line-height: 130%;
  text-align: center;
  color: var(--clr-default-100);
  transition: color var(--transition);

  &::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    height: 6rem;
    background-image: url(../img/ui/border-btn.svg);
    background-size: contain;
    background-repeat: no-repeat;
    transform: translate(-50%, -50%);
  }

  @media (hover: hover) {
    &:hover {
      color: var(--clr-default-500);
      transition: color var(--transition);
    }
  }
}

.section__link {
  min-width: 25.8rem;
  min-height: 7.6rem;

  p {
    z-index: 0;
  }

  &:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    height: 7.6rem;
    background-image: url(../img/ui/section-btn.svg);
    background-size: contain;
    background-repeat: no-repeat;
    transform: translate(-50%, -50%);
  }
}

.long__link {
  min-width: 100%;
  min-height: 5.6rem;

  p {
    z-index: 1;
  }

  &::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    height: 5.6rem;
    background-image: url(../img/ui/long-link.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    transform: translate(-50%, -50%);
  }

  @media (max-width: em(1320)) {
    margin-right: auto;
    margin-left: auto;
    min-width: 25.8rem;

    &::before {
      background-image: url(../img/ui/section-btn.svg);
    }
  }
}