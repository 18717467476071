.advantages {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    left: -49rem;
    top: 3rem;
    z-index: -1;
    border-radius: 50%;
    width: 106rem;
    height: 106rem;
    background-color: var(--clr-primary-650);
    filter: blur(6rem);
  }

  &__wrapper {
  }

  &__title {
  }

  &__text {
    margin-bottom: 5rem;
    line-height: 188%;
    text-align: center;

    p:not(:last-child) {
      margin-bottom: 2rem;
    }

    @media (max-width: em(575)) {
      margin-bottom: 3rem;
    }
  }

  &__body {
    display: flex;
    margin-bottom: 3rem;

    @media (max-width: em(1320)) {
      flex-direction: column;
    }
  }

  &__content {
    border: 0.1rem solid var(--clr-primary-400);
    padding-bottom: 3rem;
    min-width: 63rem;

    &:first-child {
      margin-right: 2.5rem;

      @media (max-width: em(1320)) {
        margin-right: initial;
        margin-bottom: 3rem;
      }
    }

    @media (max-width: em(1320)) {
      min-width: initial;
    }
  }

  &__content-title {
    display: inline-block;
    margin-bottom: 2rem;
    border-radius: 0 0 2rem 0;
    padding: 3rem;
    font-weight: 600;
    font-size: fluid-font(2.4rem, 1.8rem);
    background-color: var(--clr-default-600);
  }

  &__list {
    padding-right: 3rem;
    padding-left: 3rem;

    @media (max-width: em(575)) {
      padding-right: 1.5rem;
      padding-left: 1.5rem;
    }
  }

  &__item {
    position: relative;
    padding-left: 3.5rem;
    font-size: fluid-font(2rem, 1.8rem);

    &:not(:last-child) {
      margin-bottom: 2.5rem;
    }

    &::before, &::after {
      content: '';
      position: absolute;
    }

    &::before {
      left: 0;
      top: 0;
      border-radius: 50%;
      width: 2.4rem;
      height: 2.4rem;
      background-color: var(--clr-primary-400);
    }

    &:after {
      left: 0.6rem;
      top: 0.7rem;
      width: 1.3rem;
      height: 1.1rem;
      background-image: url(../img/ui/done.svg);
      background-size: cover;
      background-repeat: no-repeat;
    }

    &--cons {
      &::before {
        background-color: var(--clr-primary-500);
      }

      &:after {
        width: 1.1rem;
        background-image: url(../img/ui/cons.svg);
      }
    }
  }

  &__link {
  }
}
