.download {
  position: relative;
  padding-top: 10rem;
  padding-bottom: 10rem;
  background-image: -webkit-image-set(url(../img/download/bg@2x.png) 2x, url(../img/download/bg@1x.png) 1x);
  background-size: cover;
  background-repeat: no-repeat;

  &::before, &::after {
    content: '';
    position: absolute;
    left: 50%;
    width: 100%;
    height: 0.2rem;
    background-image: url(../img/download/decor.svg);
    background-size: cover;
    background-repeat: no-repeat;
    transform: translateX(-50%);
  }

  &:before {
    top: 0;
  }

  &:after {
    bottom: 0;
  }

  &__wrapper {
  }

  &__title {
    @media (max-width: em(575)) {
      margin-bottom: 2rem;
    }
  }

  &__text {
    margin-right: auto;
    margin-bottom: 5rem;
    margin-left: auto;
    max-width: 84rem;
    text-align: center;

    @media (max-width: em(575)) {
      margin-bottom: 3rem;
    }
  }

  &__list {
  }

  &__item {
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 6rem;
    min-width: 100%;
    min-height: 5.7rem;
    line-height: 175%;

    span {
      margin-right: 1.5rem;
      font-weight: 700;
      font-size: fluid-font(3.6rem, 2rem);
      color: var(--clr-default-500);
    }

    &:before {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      width: 100%;
      height: 5.7rem;
      background-image: url(../img/download/list-item.svg);
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      transform: translate(-50%, -50%);

      @media (max-width: em(1330)) {
        display: none;
      }
    }

    &:not(:last-child) {
      margin-bottom: 2rem;
    }

    @media (max-width: em(1330)) {
      border: 0.1rem solid var(--clr-default-500);
    }

    @media (max-width: em(767)) {
      padding-left: 1rem;
    }
  }

  @media (max-width: em(767)) {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}

