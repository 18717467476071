.games {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    right: -42rem;
    top: 17rem;
    z-index: -1;
    border-radius: 50%;
    width: 106rem;
    height: 106rem;
    background-color: var(--clr-primary-650);
    filter: blur(6rem);
  }

  &__wrapper {
  }

  &__title {
  }

  &__text {
    margin-bottom: 6rem;

    @media (max-width: em(767)) {
      margin-bottom: 3rem;
    }
  }

  &__content {
    position: relative;
    display: flex;
    align-items: center;

    @media (max-width: em(1200)) {
      flex-direction: column;
    }
  }

  &__list {
    margin-right: 3rem;
    max-width: 84rem;

    @media (max-width: em(1540)) {
      max-width: 63rem;
    }

    @media (max-width: em(1200)) {
      margin-right: initial;
      margin-bottom: 3rem;
      max-width: initial !important;
    }
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 6rem;

      @media (max-width: em(992)) {
        margin-bottom: 3rem;
      }
    }
  }

  &__item-title {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 3rem;
    padding-left: 6rem;
    min-height: 5.7rem;
    max-width: 63rem;
    font-size: fluid-font(3.6rem, 2.1rem);
    text-transform: uppercase;
    color: var(--clr-default-500);

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      width: 63rem;
      height: 5.7rem;
      background-image: url(../img/bonus/item-border.svg);
      background-size: contain;
      background-repeat: no-repeat;
      transform: translateY(-50%);

      @media (max-width: em(992)) {
        display: none;
      }
    }

    @media (max-width: em(992)) {
      margin-bottom: 2rem;
      border: 0.1rem solid var(--clr-default-500);
      max-width: initial;
    }

    @media (max-width: em(767)) {
      padding-left: 2rem;
    }
  }

  &__item-text {
    line-height: 188%;
  }

  &__image {
    position: absolute;
    right: -32rem;
    top: 50%;
    width: 73rem;
    height: 69rem;
    transform: translateY(-50%);

    @media (max-width: em(1540)) {
      position: initial;
      right: initial;
      top: initial;
      width: initial;
      height: initial;
      max-width: 73rem;
      transform: initial;
    }
  }
}
