.faq {

  &__wrapper {
  }

  &__title {
  }

  &__text {
    margin-bottom: 5rem;
    text-align: center;

    @media (max-width: em(575)) {
      margin-bottom: 3rem;
    }
  }

  &__list {
    display: grid;
    grid-gap: 2.5rem;
    grid-template-columns: repeat(auto-fit, minmax(27rem, 1fr));
  }

  &__item {
    border: 0.1rem solid var(--clr-default-500);
    border-radius: 1.2rem;
    padding: 3rem;

    @media (max-width: em(992)) {
      padding: 1.5rem;
    }
  }

  &__item-title {
    margin-bottom: 1.5rem;
    font-weight: 700;
    font-size: fluid-font(2.4rem, 1.8rem);
    line-height: 150%;
  }

  &__item-body {
    font-size: fluid-font(1.8rem, 1.6rem);
    line-height: 150%;
  }
}

