.deposit {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    right: -64rem;
    top: -22rem;
    z-index: -1;
    border-radius: 50%;
    width: 106rem;
    height: 106rem;
    background-color: var(--clr-primary-650);
    filter: blur(6rem);
  }

  &__wrapper {
  }

  &__title {
  }

  &__text {
    margin-bottom: 5rem;

    @media (max-width: em(992)) {
      margin-bottom: 3rem;
    }
  }

  &__list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style: disc;
    margin-bottom: 5rem;
    padding-left: 2rem;

    @media (max-width: em(992)) {
      flex-direction: column;
      align-items: initial;
      margin-bottom: 3rem;
    }
  }

  &__item {
    font-size: fluid-font(2rem, 1.8rem);

    @media (max-width: em(992)) {
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }
  }

  &__bottom-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: em(992)) {
      flex-direction: column;
      align-items: initial;
    }
  }

  &__link {
    margin-right: 2rem;

    @media (max-width: em(992)) {
      margin-right: initial;
      margin-bottom: 2rem;
    }
  }

  &__info {
    max-width: 82rem;
    font-size: 1.4rem;

    @media (max-width: em(992)) {
      max-width: initial;
    }
  }
}

