.support {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    left: -64rem;
    top: 25rem;
    z-index: -1;
    border-radius: 50%;
    width: 106rem;
    height: 106rem;
    background-color: var(--clr-primary-650);
    filter: blur(6rem);
  }

  &__wrapper {
  }

  &__title {
  }

  &__text {
    p:not(:last-child) {
      margin-bottom: 3rem;
    }
  }
}
