/* stylelint-disable */

/* stylelint-disable */

/* stylelint-disable scss/operator-no-unspaced */

/* stylelint-disable scss/dollar-variable-colon-newline-after */

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-Medium.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "ZonaPro";
  src: url("../fonts/ZonaPro-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Strong";
  src: url("../fonts/Strong-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "ZonaPro";
  src: url("../fonts/ZonaPro-Bold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

html {
  font-family: var(--font-family);
  font-weight: var(--font-weight);
  font-size: 62.5%;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  scroll-behavior: smooth;
}

html,
body {
  height: 100%;
}

body {
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  text-decoration: none;
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

button {
  border: none;
  text-align: left;
  background-color: transparent;
  cursor: pointer;
}

ul,
ol {
  list-style: none;
}

input {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;
}

input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration {
  display: none;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.focus-visible {
  outline: auto 1px;
}

.ios .lock {
  position: relative;
}

/* Forms
   ========================================================================== */

/**
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */

button,
select {
  text-transform: none;
}

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

/**
 * Remove the inner border and padding in Firefox.
 */

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus styles unset by the previous rule.
 */

button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

:root {
  --font-family: "Zona Pro", sans-serif;
  --font-weight: 400;
  --content-width: 130rem;
  --container-offset: 1.5rem;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --transition: 0.3s;
  --clr-default-100: #fff;
  --clr-default-200: rgba(255, 255, 255, 0.8);
  --clr-default-500: #00D6DA;
  --clr-default-550: rgba(255, 255, 255, 0.66);
  --clr-default-600: rgba(255, 255, 255, 0.05);
  --clr-default-800: #0B1D24;
  --clr-default-900: #000;
  --clr-primary-400: #22C99D;
  --clr-primary-500: #FF6900;
  --clr-primary-600: #333A48;
  --clr-primary-650: rgba(13, 46, 58, 0.45);
}

body {
  font-size: 1.6rem;
  color: var(--clr-default-100);
  background-color: var(--clr-default-800);
}

body.lock {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  min-width: 32rem;
  min-height: 100%;
}

.wrapper::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: opacity var(--transition), visibility var(--transition);
}

.wrapper[data-overlay=true]::before {
  opacity: 1;
  visibility: visible;
  transition: opacity var(--transition), visibility var(--transition);
}

main {
  flex-grow: 1;
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: var(--container-offset);
  padding-left: var(--container-offset);
  width: 100%;
  max-width: var(--container-width);
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.section {
  padding-top: 14rem;
}

.title {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4rem;
  min-height: 10.7rem;
  font-size: clamp(
    2.1rem,
    1.6247524752rem + 1.4851485149vw,
    3.6rem
  );
  line-height: 133%;
  text-transform: uppercase;
}

.title .span__wrapper {
  color: var(--clr-default-100);
}

.title span {
  color: var(--clr-default-500);
}

.title:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  height: 10.7rem;
  background-image: url(../img/ui/decor-title.svg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transform: translate(-50%, -50%);
}

.text {
  line-height: 188%;
}

.main__link {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 2.5rem;
  padding-left: 2.5rem;
  width: max-content;
  min-width: 22rem;
  min-height: 6rem;
  font-family: "Strong";
  font-weight: 400;
  font-size: 2rem;
  line-height: 130%;
  text-align: center;
  color: var(--clr-default-100);
  transition: color var(--transition);
}

.main__link::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  height: 6rem;
  background-image: url(../img/ui/border-btn.svg);
  background-size: contain;
  background-repeat: no-repeat;
  transform: translate(-50%, -50%);
}

.section__link {
  min-width: 25.8rem;
  min-height: 7.6rem;
}

.section__link p {
  z-index: 0;
}

.section__link:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  height: 7.6rem;
  background-image: url(../img/ui/section-btn.svg);
  background-size: contain;
  background-repeat: no-repeat;
  transform: translate(-50%, -50%);
}

.long__link {
  min-width: 100%;
  min-height: 5.6rem;
}

.long__link p {
  z-index: 1;
}

.long__link::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  height: 5.6rem;
  background-image: url(../img/ui/long-link.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  transform: translate(-50%, -50%);
}

.menu__list {
  display: flex;
  align-items: center;
}

.menu__item {
  position: relative;
}

.menu__item::before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -4px;
  width: 0;
  height: 2px;
  background-color: var(--clr-default-500);
  transform: translate(-50%, 0);
  transition: width 0.3s;
}

.menu__item a {
  font-size: 1.7rem;
  color: var(--clr-default-100);
}

.menu__item:not(:last-child) {
  margin-right: 3rem;
}

.language {
  position: relative;
  z-index: 1;
  margin-right: 3rem;
  font-weight: 600;
  cursor: pointer;
}

.language__current {
  position: relative;
  display: flex;
  align-items: center;
  min-width: 7rem;
}

.language__current::before {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  width: 1.2rem;
  height: 0.8rem;
  background-image: url(../img/ui/language-arrow.svg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transform: translateY(-50%) rotate(0);
  transition: transform var(--transition);
}

.language__dropdown {
  position: absolute;
  left: -125%;
  top: 100%;
  margin-top: 1.6rem;
  border-radius: 0.4rem;
  padding-right: 1.2rem;
  padding-left: 1.2rem;
  width: max-content;
  background-color: var(--clr-default-100);
  opacity: 0;
  visibility: hidden;
  transition: opacity var(--transition), visibility var(--transition);
}

.language__img {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  margin-right: 1.2rem;
  border-radius: 50%;
  width: 3.2rem;
  height: 3.2rem;
}

.language__img img {
  border-radius: 50%;
}

.language__dropdown-item {
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
}

.language__dropdown-item:not(:last-child) {
  margin-bottom: 1rem;
}

.language__dropdown-item a {
  display: flex;
  align-items: center;
  padding: 3px 6px;
  font-family: "Poppins";
  font-weight: 500;
  color: var(--clr-primary-600);
  transition: color var(--transition);
}

.language__dropdown-item a:hover {
  color: var(--clr-default-500);
  transition: color var(--transition);
}

.language:focus .language__dropdown,
.language:hover .language__dropdown {
  opacity: 1;
  visibility: visible;
  transition: opacity var(--transition), visibility var(--transition);
}

.language:focus .language__current::before,
.language:hover .language__current::before {
  transform: translateY(-50%) rotate(-180deg);
  transition: transform var(--transition);
}

.logo {
  margin-right: 2rem;
  max-width: 9rem;
}

.burger {
  display: none;
}

.header {
  z-index: 1;
}

.header__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 5rem;
}

.footer {
  padding-top: 10rem;
  padding-bottom: 10rem;
}

.footer__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hero {
  position: relative;
  margin-top: -11rem;
  padding-top: 15rem;
  padding-bottom: 40rem;
  background-image: -webkit-image-set(url(../img/hero/bg@2x.jpg) 2x, url(../img/hero/bg@1x.jpg) 1x);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.hero::before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 100%;
  height: 31rem;
  background-image: url(../img/hero/decor-border.svg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transform: translateX(-50%);
}

.hero__wrapper {
  position: relative;
}

.hero__content {
  max-width: 63rem;
}

.hero__title {
  margin-bottom: 1.5rem;
  font-weight: 700;
  font-size: clamp(
    2.4rem,
    1.5128712871rem + 2.7722772277vw,
    5.2rem
  );
}

.hero__text {
  margin-bottom: 3rem;
}

.hero__text p {
  line-height: 188%;
}

.hero__text p:not(:last-child) {
  margin-bottom: 2rem;
}

.hero__image {
  position: absolute;
  right: -51.5rem;
  top: 0;
  width: 113.5rem;
}

.info {
  margin-top: -7rem;
}

.info__wrapper {
  position: relative;
  z-index: 1;
}

.info__text {
  margin-bottom: 3rem;
}

.info__text p {
  line-height: 214%;
  color: var(--clr-default-200);
}

.info__text p:not(:last-child) {
  margin-bottom: 2rem;
}

.info__table {
  overflow: auto;
  margin-bottom: 3rem;
  border: 0.1rem solid var(--clr-default-500);
  padding-top: 4rem;
  padding-right: 3rem;
  padding-bottom: 4rem;
  padding-left: 3rem;
}

.info__table table {
  border-collapse: collapse;
}

.info__table table tbody tr:not(:last-child) {
  border-bottom: 0.1rem solid var(--clr-default-600);
}

.info__table table tbody td {
  vertical-align: top;
  padding-top: 0.8rem;
  padding-right: 1.2rem;
  padding-bottom: 0.8rem;
  padding-left: 1.2rem;
  line-height: 150%;
}

.info__table table tbody td:first-child {
  min-width: 32rem;
}

.info__bottom-text {
  line-height: 214%;
  color: var(--clr-default-200);
}

.advantages {
  position: relative;
}

.advantages::before {
  content: "";
  position: absolute;
  left: -49rem;
  top: 3rem;
  z-index: -1;
  border-radius: 50%;
  width: 106rem;
  height: 106rem;
  background-color: var(--clr-primary-650);
  filter: blur(6rem);
}

.advantages__text {
  margin-bottom: 5rem;
  line-height: 188%;
  text-align: center;
}

.advantages__text p:not(:last-child) {
  margin-bottom: 2rem;
}

.advantages__body {
  display: flex;
  margin-bottom: 3rem;
}

.advantages__content {
  border: 0.1rem solid var(--clr-primary-400);
  padding-bottom: 3rem;
  min-width: 63rem;
}

.advantages__content:first-child {
  margin-right: 2.5rem;
}

.advantages__content-title {
  display: inline-block;
  margin-bottom: 2rem;
  border-radius: 0 0 2rem 0;
  padding: 3rem;
  font-weight: 600;
  font-size: clamp(
    1.8rem,
    1.6099009901rem + 0.5940594059vw,
    2.4rem
  );
  background-color: var(--clr-default-600);
}

.advantages__list {
  padding-right: 3rem;
  padding-left: 3rem;
}

.advantages__item {
  position: relative;
  padding-left: 3.5rem;
  font-size: clamp(
    1.8rem,
    1.7366336634rem + 0.198019802vw,
    2rem
  );
}

.advantages__item:not(:last-child) {
  margin-bottom: 2.5rem;
}

.advantages__item::before,
.advantages__item::after {
  content: "";
  position: absolute;
}

.advantages__item::before {
  left: 0;
  top: 0;
  border-radius: 50%;
  width: 2.4rem;
  height: 2.4rem;
  background-color: var(--clr-primary-400);
}

.advantages__item:after {
  left: 0.6rem;
  top: 0.7rem;
  width: 1.3rem;
  height: 1.1rem;
  background-image: url(../img/ui/done.svg);
  background-size: cover;
  background-repeat: no-repeat;
}

.advantages__item--cons::before {
  background-color: var(--clr-primary-500);
}

.advantages__item--cons:after {
  width: 1.1rem;
  background-image: url(../img/ui/cons.svg);
}

.register {
  position: relative;
}

.register::before {
  content: "";
  position: absolute;
  right: -49rem;
  top: 33rem;
  z-index: -1;
  border-radius: 50%;
  width: 106rem;
  height: 106rem;
  background-color: var(--clr-primary-650);
  filter: blur(6rem);
}

.register__title {
  margin-bottom: 4rem;
}

.register__info {
  position: relative;
}

.register__content {
  max-width: 85.5rem;
}

.register__text {
  margin-bottom: 3rem;
}

.register__text p:not(:last-child) {
  margin-bottom: 2rem;
}

.register__list {
  list-style: disc;
  padding-left: 2rem;
}

.register__list--first {
  margin-bottom: 3rem;
}

.register__item {
  font-size: clamp(
    1.8rem,
    1.7366336634rem + 0.198019802vw,
    2rem
  );
  line-height: 140%;
}

.register__item:not(:last-child) {
  margin-bottom: 2rem;
}

.register__image {
  position: absolute;
  right: -14.5rem;
  top: 50%;
  width: 56.3rem;
  height: 44.6rem;
  transform: translateY(-50%);
}

.app {
  padding-bottom: 14rem;
}

.app__title {
  margin-bottom: 4rem;
}

.app__text {
  margin-bottom: 5rem;
  text-align: center;
}

.app__text p:not(:last-child) {
  margin-bottom: 3rem;
}

.app__link {
  margin-right: auto;
  margin-left: auto;
}

.download {
  position: relative;
  padding-top: 10rem;
  padding-bottom: 10rem;
  background-image: -webkit-image-set(url(../img/download/bg@2x.png) 2x, url(../img/download/bg@1x.png) 1x);
  background-size: cover;
  background-repeat: no-repeat;
}

.download::before,
.download::after {
  content: "";
  position: absolute;
  left: 50%;
  width: 100%;
  height: 0.2rem;
  background-image: url(../img/download/decor.svg);
  background-size: cover;
  background-repeat: no-repeat;
  transform: translateX(-50%);
}

.download:before {
  top: 0;
}

.download:after {
  bottom: 0;
}

.download__text {
  margin-right: auto;
  margin-bottom: 5rem;
  margin-left: auto;
  max-width: 84rem;
  text-align: center;
}

.download__item {
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 6rem;
  min-width: 100%;
  min-height: 5.7rem;
  line-height: 175%;
}

.download__item span {
  margin-right: 1.5rem;
  font-weight: 700;
  font-size: clamp(
    2rem,
    1.4930693069rem + 1.5841584158vw,
    3.6rem
  );
  color: var(--clr-default-500);
}

.download__item:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  height: 5.7rem;
  background-image: url(../img/download/list-item.svg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transform: translate(-50%, -50%);
}

.download__item:not(:last-child) {
  margin-bottom: 2rem;
}

.betting__wrapper {
  padding-top: 19rem;
  padding-bottom: 19rem;
  background-image: url(../img/betting/bg.svg);
  background-size: contain;
  background-repeat: no-repeat;
}

.betting__text {
  margin-right: auto;
  margin-bottom: 5rem;
  margin-left: auto;
  max-width: 84rem;
  text-align: center;
}

.betting__link {
  margin-right: auto;
  margin-left: auto;
}

.sport__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sport__info {
  margin-right: 3rem;
  max-width: 76rem;
}

.sport__text {
  margin-bottom: 5rem;
}

.sport__text p:not(:last-child) {
  margin-bottom: 3rem;
}

.sport__image {
  max-width: 52.5rem;
}

.live {
  position: relative;
}

.live::before {
  content: "";
  position: absolute;
  left: -49rem;
  top: -7rem;
  z-index: -1;
  border-radius: 50%;
  width: 106rem;
  height: 106rem;
  background-color: var(--clr-primary-650);
  filter: blur(6rem);
}

.live__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.live__info {
  max-width: 64rem;
}

.live__text {
  margin-bottom: 5rem;
}

.live__text p:not(:last-child) {
  margin-bottom: 3rem;
}

.live__image {
  margin-right: 3rem;
  max-width: 65rem;
}

.summary__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.summary__info {
  margin-right: 3rem;
  max-width: 64rem;
}

.summary__text {
  margin-bottom: 5rem;
}

.summary__text p:not(:last-child) {
  margin-bottom: 3rem;
}

.summary__image {
  max-width: 65rem;
}

.bonus {
  position: relative;
}

.bonus::before {
  content: "";
  position: absolute;
  left: -70rem;
  top: 85rem;
  z-index: -1;
  border-radius: 50%;
  width: 106rem;
  height: 106rem;
  background-color: var(--clr-primary-650);
  filter: blur(6rem);
}

.bonus::after {
  content: "";
  position: absolute;
  right: -40rem;
  top: 112rem;
  z-index: -1;
  border-radius: 50%;
  width: 106rem;
  height: 106rem;
  background-color: var(--clr-primary-650);
  filter: blur(6rem);
}

.bonus__text {
  margin-bottom: 6rem;
}

.bonus__content {
  position: relative;
  display: flex;
  align-items: center;
}

.bonus__list {
  margin-right: 3rem;
  max-width: 74.5rem;
}

.bonus__item:not(:last-child) {
  margin-bottom: 6rem;
}

.bonus__item-title {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 3rem;
  padding-left: 6rem;
  min-height: 5.7rem;
  max-width: 63rem;
  font-size: clamp(
    2.1rem,
    1.6247524752rem + 1.4851485149vw,
    3.6rem
  );
  text-transform: uppercase;
  color: var(--clr-default-500);
}

.bonus__item-title::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 63rem;
  height: 5.7rem;
  background-image: url(../img/bonus/item-border.svg);
  background-size: contain;
  background-repeat: no-repeat;
  transform: translateY(-50%);
}

.bonus__item-text {
  line-height: 188%;
}

.bonus__image {
  position: absolute;
  right: -12rem;
  top: 50%;
  width: 61rem;
  height: 71rem;
  transform: translateY(-50%);
}

.betbonus__wrapper {
  margin-bottom: 10rem;
}

.betbonus__text {
  margin-right: auto;
  margin-bottom: 5rem;
  margin-left: auto;
  max-width: 90rem;
  text-align: center;
}

.betbonus__text p:not(:last-child) {
  margin-bottom: 3rem;
}

.betbonus__link {
  margin-right: auto;
  margin-left: auto;
}

.betbonus__banner {
  max-width: 129.5rem;
}

.vip__text p:not(:last-child) {
  margin-bottom: 3rem;
}

.tournament {
  position: relative;
}

.tournament::before {
  content: "";
  position: absolute;
  right: -49rem;
  top: -24rem;
  z-index: -1;
  border-radius: 50%;
  width: 106rem;
  height: 106rem;
  background-color: var(--clr-primary-650);
  filter: blur(6rem);
}

.tournament__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tournament__info {
  margin-right: 3rem;
  max-width: 61rem;
}

.tournament__text {
  margin-bottom: 5rem;
}

.tournament__text p:not(:last-child) {
  margin-bottom: 3rem;
}

.tournament__image {
  max-width: 65rem;
}

.online__text {
  margin-bottom: 5rem;
}

.online__text p:not(:last-child) {
  margin-bottom: 3rem;
}

.online__table {
  overflow: auto;
  border: 0.1rem solid var(--clr-primary-400);
  padding: 4rem;
}

.online__table table {
  border-collapse: collapse;
  min-width: 100%;
}

.online__table table thead th {
  padding-top: 0.8rem;
  padding-right: 1rem;
  padding-bottom: 0.8rem;
  padding-left: 1rem;
  font-size: 1.6rem;
  line-height: 150%;
  text-align: start;
  color: var(--clr-default-550);
}

.online__table table thead th:first-child {
  min-width: 30rem;
}

.online__table table thead th:nth-child(2) {
  width: 30rem;
}

.online__table table thead th:last-child {
  text-align: end;
}

.online__table table tbody tr {
  border-bottom: 0.1rem solid var(--clr-default-600);
}

.online__table table tbody td {
  padding-top: 0.8rem;
  padding-right: 1rem;
  padding-bottom: 0.8rem;
  padding-left: 1rem;
  line-height: 150%;
}

.online__table table tbody td:last-child {
  text-align: end;
}

.other__table {
  overflow: auto;
  border: 0.1rem solid var(--clr-primary-400);
  padding-top: 6rem;
  padding-right: 4rem;
  padding-bottom: 6rem;
  padding-left: 4rem;
}

.other__table table {
  border-collapse: collapse;
}

.other__table table thead th {
  padding-top: 0.8rem;
  padding-right: 1.5rem;
  padding-bottom: 0.8rem;
  padding-left: 1.5rem;
  line-height: 150%;
  line-height: 150%;
  text-align: start;
  color: var(--clr-default-550);
}

.other__table table thead th:first-child {
  padding-left: initial;
  min-width: 22rem;
}

.other__table table tbody tr {
  border-bottom: 0.1rem solid var(--clr-default-600);
}

.other__table table tbody td {
  padding-top: 0.8rem;
  padding-right: 1.5rem;
  padding-bottom: 0.8rem;
  padding-left: 1.5rem;
  line-height: 150%;
}

.other__table table tbody td:first-child {
  padding-left: initial;
}

.games {
  position: relative;
}

.games::before {
  content: "";
  position: absolute;
  right: -42rem;
  top: 17rem;
  z-index: -1;
  border-radius: 50%;
  width: 106rem;
  height: 106rem;
  background-color: var(--clr-primary-650);
  filter: blur(6rem);
}

.games__text {
  margin-bottom: 6rem;
}

.games__content {
  position: relative;
  display: flex;
  align-items: center;
}

.games__list {
  margin-right: 3rem;
  max-width: 84rem;
}

.games__item:not(:last-child) {
  margin-bottom: 6rem;
}

.games__item-title {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 3rem;
  padding-left: 6rem;
  min-height: 5.7rem;
  max-width: 63rem;
  font-size: clamp(
    2.1rem,
    1.6247524752rem + 1.4851485149vw,
    3.6rem
  );
  text-transform: uppercase;
  color: var(--clr-default-500);
}

.games__item-title::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 63rem;
  height: 5.7rem;
  background-image: url(../img/bonus/item-border.svg);
  background-size: contain;
  background-repeat: no-repeat;
  transform: translateY(-50%);
}

.games__item-text {
  line-height: 188%;
}

.games__image {
  position: absolute;
  right: -32rem;
  top: 50%;
  width: 73rem;
  height: 69rem;
  transform: translateY(-50%);
}

.license {
  position: relative;
}

.license::before {
  content: "";
  position: absolute;
  left: -42rem;
  top: 17rem;
  z-index: -1;
  border-radius: 50%;
  width: 106rem;
  height: 106rem;
  background-color: var(--clr-primary-650);
  filter: blur(6rem);
}

.license__wrapper {
  margin-bottom: 10rem;
}

.license__text {
  text-align: center;
}

.license__text p:not(:last-child) {
  margin-bottom: 3rem;
}

.license__banner {
  margin-right: auto;
  margin-left: auto;
}

.faq__text {
  margin-bottom: 5rem;
  text-align: center;
}

.faq__list {
  display: grid;
  grid-gap: 2.5rem;
  grid-template-columns: repeat(auto-fit, minmax(27rem, 1fr));
}

.faq__item {
  border: 0.1rem solid var(--clr-default-500);
  border-radius: 1.2rem;
  padding: 3rem;
}

.faq__item-title {
  margin-bottom: 1.5rem;
  font-weight: 700;
  font-size: clamp(
    1.8rem,
    1.6099009901rem + 0.5940594059vw,
    2.4rem
  );
  line-height: 150%;
}

.faq__item-body {
  font-size: clamp(
    1.6rem,
    1.5366336634rem + 0.198019802vw,
    1.8rem
  );
  line-height: 150%;
}

.deposit {
  position: relative;
}

.deposit::before {
  content: "";
  position: absolute;
  right: -64rem;
  top: -22rem;
  z-index: -1;
  border-radius: 50%;
  width: 106rem;
  height: 106rem;
  background-color: var(--clr-primary-650);
  filter: blur(6rem);
}

.deposit__text {
  margin-bottom: 5rem;
}

.deposit__list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: disc;
  margin-bottom: 5rem;
  padding-left: 2rem;
}

.deposit__item {
  font-size: clamp(
    1.8rem,
    1.7366336634rem + 0.198019802vw,
    2rem
  );
}

.deposit__bottom-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.deposit__link {
  margin-right: 2rem;
}

.deposit__info {
  max-width: 82rem;
  font-size: 1.4rem;
}

.support {
  position: relative;
}

.support::before {
  content: "";
  position: absolute;
  left: -64rem;
  top: 25rem;
  z-index: -1;
  border-radius: 50%;
  width: 106rem;
  height: 106rem;
  background-color: var(--clr-primary-650);
  filter: blur(6rem);
}

.support__text p:not(:last-child) {
  margin-bottom: 3rem;
}

.resume__body {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.resume__image {
  flex-shrink: 0;
  margin-right: 3rem;
  max-width: 46rem;
}

.resume__content {
  max-width: 63rem;
}

.resume__text {
  margin-bottom: 5rem;
}

@media (max-width: 105em) {
  .hero {
    padding-bottom: 25rem;
  }

  .hero__wrapper {
    display: flex;
  }

  .hero__content {
    margin-right: 3rem;
    max-width: 50rem;
  }

  .hero__image {
    position: initial;
    right: initial;
    top: initial;
    width: initial;
    max-width: 113.5rem;
  }
}

@media (max-width: 100em) {
  .register__info {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .register__content {
    margin-right: 2rem;
    max-width: 55rem;
  }

  .register__image {
    position: initial;
    right: initial;
    top: initial;
    width: initial;
    height: initial;
    max-width: 56.3rem;
    transform: initial;
  }
}

@media (max-width: 75em) {
  .bonus__content {
    flex-direction: column;
  }

  .bonus__list {
    margin-right: initial;
    margin-bottom: 3rem;
    max-width: initial !important;
  }

  .games__content {
    flex-direction: column;
  }

  .games__list {
    margin-right: initial;
    margin-bottom: 3rem;
    max-width: initial !important;
  }
}

@media (max-width: 62em) {
  .section {
    padding-top: 5rem !important;
  }

  .menu__list {
    flex-direction: column;
    margin: auto;
    padding-block: 4rem;
  }

  .menu__item a {
    font-weight: 600;
    font-size: 2rem;
  }

  .menu__item:not(:last-child) {
    margin-right: 0;
    margin-bottom: 3rem;
  }

  .menu {
    position: fixed;
    right: -100%;
    top: 0;
    z-index: 10;
    display: flex;
    overflow: auto;
    width: 100%;
    height: 100%;
    max-width: 32rem;
    background-color: var(--clr-default-900);
    transition: right var(--transition);
  }

  .menu--active {
    right: 0;
    transition: right var(--transition);
  }

  .language {
    margin-right: 30px;
    margin-left: auto;
  }

  .burger {
    position: relative;
    z-index: 15;
    display: block;
    width: 2.5rem;
    height: 2rem;
    background-color: transparent;
  }

  .burger::after,
  .burger::before,
  .burger span {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 0.4rem;
    background-color: #fff;
  }

  .burger::before {
    top: 0;
    transition: transform var(--transition), top var(--transition);
  }

  .burger::after {
    bottom: 0;
    transition: transform var(--transition), bottom var(--transition);
  }

  .burger span {
    top: 0.8rem;
    transition: transform var(--transition);
  }

  .burger--active::before {
    top: 0.8rem;
    transform: rotate(45deg);
    transition: transform var(--transition), top var(--transition);
  }

  .burger--active::after {
    bottom: 0.8rem;
    transform: rotate(-45deg);
    transition: transform var(--transition), bottom var(--transition);
  }

  .burger--active span {
    transform: scale(0);
    transition: transform var(--transition);
  }

  .header__wrapper {
    padding-top: 3rem;
  }

  .header__link {
    margin-right: 2.5rem;
  }

  .hero {
    padding-bottom: 17rem;
  }

  .hero__wrapper {
    flex-direction: column;
  }

  .hero__content {
    margin-right: initial;
    margin-bottom: 3rem;
    max-width: initial;
  }

  .info {
    margin-top: -11rem;
  }

  .info__wrapper {
    z-index: initial;
  }

  .info__table table tbody td:first-child {
    min-width: 23rem;
  }

  .register__info {
    flex-direction: column;
  }

  .register__content {
    margin-right: initial;
    margin-bottom: 3rem;
    max-width: initial;
  }

  .app {
    padding-bottom: 5rem !important;
  }

  .app__title {
    margin-bottom: 3rem;
  }

  .app__text p:not(:last-child) {
    margin-bottom: 1.5rem;
  }

  .app__text {
    margin-bottom: 3rem;
  }

  .betting__wrapper {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .sport__content {
    flex-direction: column;
  }

  .sport__info {
    margin-right: initial;
    margin-bottom: 3rem;
    max-width: initial !important;
  }

  .live__content {
    flex-direction: column-reverse;
  }

  .live__info {
    margin-bottom: 3rem;
    max-width: initial !important;
  }

  .live__image {
    margin-right: initial;
  }

  .summary__content {
    flex-direction: column;
  }

  .summary__info {
    margin-right: initial;
    margin-bottom: 3rem;
    max-width: initial !important;
  }

  .bonus__item:not(:last-child) {
    margin-bottom: 3rem;
  }

  .bonus__item-title::before {
    display: none;
  }

  .bonus__item-title {
    margin-bottom: 2rem;
    border: 0.1rem solid var(--clr-default-500);
    max-width: initial;
  }

  .tournament__content {
    flex-direction: column;
  }

  .tournament__info {
    margin-right: initial;
    margin-bottom: 3rem;
    max-width: initial !important;
  }

  .online__table table thead th:last-child {
    text-align: start;
  }

  .online__table table tbody td:last-child {
    text-align: start;
  }

  .online__table table {
    min-width: 79rem;
  }

  .online__table {
    padding: 2.5rem;
  }

  .other__table table thead th:first-child {
    min-width: 15rem;
  }

  .games__item:not(:last-child) {
    margin-bottom: 3rem;
  }

  .games__item-title::before {
    display: none;
  }

  .games__item-title {
    margin-bottom: 2rem;
    border: 0.1rem solid var(--clr-default-500);
    max-width: initial;
  }

  .faq__item {
    padding: 1.5rem;
  }

  .deposit__text {
    margin-bottom: 3rem;
  }

  .deposit__list {
    flex-direction: column;
    align-items: initial;
    margin-bottom: 3rem;
  }

  .deposit__item:not(:last-child) {
    margin-bottom: 1rem;
  }

  .deposit__bottom-wrapper {
    flex-direction: column;
    align-items: initial;
  }

  .deposit__link {
    margin-right: initial;
    margin-bottom: 2rem;
  }

  .deposit__info {
    max-width: initial;
  }

  .resume__body {
    flex-direction: column-reverse;
  }

  .resume__image {
    margin-right: initial;
  }

  .resume__content {
    margin-bottom: 5rem;
    max-width: initial;
  }
}

@media (max-width: 97.5em) {
  .footer {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}

@media (max-width: 96.25em) {
  .bonus__list {
    max-width: 63rem;
  }

  .bonus__image {
    position: initial;
    right: initial;
    top: initial;
    width: initial;
    height: initial;
    max-width: 61rem;
    transform: initial;
  }

  .games__list {
    max-width: 63rem;
  }

  .games__image {
    position: initial;
    right: initial;
    top: initial;
    width: initial;
    height: initial;
    max-width: 73rem;
    transform: initial;
  }
}

@media (max-width: 93.75em) {
  .betting__wrapper {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
}

@media (max-width: 86.25em) {
  .sport__info {
    max-width: 55rem;
  }

  .live__info {
    max-width: 55rem;
  }

  .summary__info {
    max-width: 55rem;
  }

  .tournament__info {
    max-width: 55rem;
  }
}

@media (max-width: 83.125em) {
  .download__item:before {
    display: none;
  }

  .download__item {
    border: 0.1rem solid var(--clr-default-500);
  }
}

@media (max-width: 82.5em) {
  .section {
    padding-top: 10rem;
  }

  .title:before {
    display: none;
  }

  .title {
    display: block;
    margin-right: auto;
    margin-left: auto;
    min-height: initial;
    text-align: center;
  }

  .long__link {
    margin-right: auto;
    margin-left: auto;
    min-width: 25.8rem;
  }

  .long__link::before {
    background-image: url(../img/ui/section-btn.svg);
  }

  .advantages__body {
    flex-direction: column;
  }

  .advantages__content:first-child {
    margin-right: initial;
    margin-bottom: 3rem;
  }

  .advantages__content {
    min-width: initial;
  }

  .app {
    padding-bottom: 10rem;
  }

  .license__wrapper {
    margin-bottom: 5rem;
  }
}

@media (max-width: 73.75em) {
  .online__table table thead th:first-child {
    min-width: initial;
  }

  .online__table table thead th:nth-child(2) {
    width: initial;
  }
}

@media (max-width: 47.9375em) {
  .title {
    margin-bottom: 2.5rem;
  }

  .language__current {
    min-width: 5.5rem;
  }

  .footer {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .info__table table tbody td:first-child {
    min-width: 15rem;
  }

  .download__item {
    padding-left: 1rem;
  }

  .download {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .bonus__text {
    margin-bottom: 3rem;
  }

  .bonus__item-title {
    padding-left: 2rem;
  }

  .betbonus__text p:not(:last-child) {
    margin-bottom: 1.5rem;
  }

  .betbonus__text {
    margin-bottom: 3rem;
  }

  .online__text {
    margin-bottom: 3rem;
  }

  .other__table table {
    min-width: 74rem;
  }

  .other__table {
    padding-top: 2rem;
    padding-right: 2rem;
    padding-bottom: 2rem;
    padding-left: 2rem;
  }

  .games__text {
    margin-bottom: 3rem;
  }

  .games__item-title {
    padding-left: 2rem;
  }
}

@media (max-width: 35.9375em) {
  .header__link {
    margin-right: initial;
  }

  .info__table table {
    min-width: 50rem;
  }

  .info__table {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }

  .advantages__text {
    margin-bottom: 3rem;
  }

  .advantages__list {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }

  .register__title {
    margin-bottom: 3rem;
  }

  .download__title {
    margin-bottom: 2rem;
  }

  .download__text {
    margin-bottom: 3rem;
  }

  .betting__title {
    margin-bottom: 3rem;
  }

  .betting__text {
    margin-bottom: 3rem;
  }

  .online__table {
    padding: 1.5rem;
  }

  .other__table table thead th:first-child {
    min-width: 12rem;
  }

  .faq__text {
    margin-bottom: 3rem;
  }

  .resume__content {
    margin-bottom: 3rem;
  }

  .resume__text {
    margin-bottom: 3rem;
  }
}

@media (hover: hover) {
  .main__link:hover {
    color: var(--clr-default-500);
    transition: color var(--transition);
  }

  .menu__item:hover::before {
    width: 100%;
    transition: width 0.3s;
  }
}