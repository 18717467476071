.sport {

  &__wrapper {
  }

  &__title {
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: em(992)) {
      flex-direction: column;
    }
  }

  &__info {
    margin-right: 3rem;
    max-width: 76rem;

    @media (max-width: em(1380)) {
      max-width: 55rem;
    }

    @media (max-width: em(992)) {
      margin-right: initial;
      margin-bottom: 3rem;
      max-width: initial !important;
    }
  }

  &__text {
    margin-bottom: 5rem;

    p:not(:last-child) {
      margin-bottom: 3rem;
    }
  }

  &__link {
  }

  &__image {
    max-width: 52.5rem;
  }
}
