.online {

  &__wrapper {
  }

  &__title {
  }

  &__text {
    margin-bottom: 5rem;

    p:not(:last-child) {
      margin-bottom: 3rem;
    }

    @media (max-width: em(767)) {
      margin-bottom: 3rem;
    }
  }

  &__table {
    overflow: auto;
    border: 0.1rem solid var(--clr-primary-400);
    padding: 4rem;

    table {
      border-collapse: collapse;
      min-width: 100%;

      thead {
        th {
          padding-top: 0.8rem;
          padding-right: 1rem;
          padding-bottom: 0.8rem;
          padding-left: 1rem;
          font-size: 1.6rem;
          line-height: 150%;
          text-align: start;
          color: var(--clr-default-550);

          &:first-child {
            min-width: 30rem;

            @media (max-width: em(1180)) {
              min-width: initial;
            }
          }

          &:nth-child(2) {
            width: 30rem;

            @media (max-width: em(1180)) {
              width: initial;
            }
          }

          &:last-child {
            text-align: end;

            @media (max-width: em(992)) {
              text-align: start;
            }
          }
        }
      }

      tbody {
        tr {
          border-bottom: 0.1rem solid var(--clr-default-600);
        }

        td {
          padding-top: 0.8rem;
          padding-right: 1rem;
          padding-bottom: 0.8rem;
          padding-left: 1rem;
          line-height: 150%;

          &:last-child {
            text-align: end;

            @media (max-width: em(992)) {
              text-align: start;
            }
          }
        }
      }

      @media (max-width: em(992)) {
        min-width: 79rem;
      }
    }

    @media (max-width: em(992)) {
      padding: 2.5rem;
    }

    @media (max-width: em(575)) {
      padding: 1.5rem;
    }
  }
}

