.resume {

  &__wrapper {
  }

  &__title {
  }

  &__body {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: em(992)) {
      flex-direction: column-reverse;
    }
  }

  &__image {
    flex-shrink: 0;
    margin-right: 3rem;
    max-width: 46rem;

    @media (max-width: em(992)) {
      margin-right: initial;
    }
  }

  &__content {
    max-width: 63rem;

    @media (max-width: em(992)) {
      margin-bottom: 5rem;
      max-width: initial;
    }

    @media (max-width: em(575)) {
      margin-bottom: 3rem;
    }
  }

  &__text {
    margin-bottom: 5rem;

    @media (max-width: em(575)) {
      margin-bottom: 3rem;
    }
  }

  &__link {
  }
}
