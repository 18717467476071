.betting {
  &__wrapper {
    padding-top: 19rem;
    padding-bottom: 19rem;
    background-image: url(../img/betting/bg.svg);
    background-size: contain;
    background-repeat: no-repeat;

    @media (max-width: em(1500)) {
      padding-top: 10rem;
      padding-bottom: 10rem;
    }

    @media (max-width: em(992)) {
      padding-top: 5rem !important;
      padding-bottom: 5rem !important;
    }
  }

  &__title {
    @media (max-width: em(575)) {
      margin-bottom: 3rem;
    }
  }

  &__text {
    margin-right: auto;
    margin-bottom: 5rem;
    margin-left: auto;
    max-width: 84rem;
    text-align: center;

    @media (max-width: em(575)) {
      margin-bottom: 3rem;
    }
  }

  &__link {
    margin-right: auto;
    margin-left: auto;
  }
}

